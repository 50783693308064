<template>
    <div class="liveList">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="liveList_main">
            <div class="liveList_main_left">
                <p>
                    <img src="@/assets/course/livePage_icon.png" alt />
                    <span>直播分类</span>
                </p>
                <p v-for="(item,index) in classifyList" :key="index" :class="tabIndex == item.id ? 'liveList_main_left_active' : ''" @click="tabChange(item)">
                    <span>{{ item.name }}</span>
                </p>
            </div>
            <div class="liveList_main_right">
                <div class="liveList_main_right_swiper" v-if="freeLiveList.length > 0">
                    <div class="liveList_main_right_swiper_header">直播课 {{ newDate }}</div>
                    <div class="swiper-container" id="a1">
                        <div class="swiper-wrapper" ref="swiper">
                            <div v-for="(item,index) in freeLiveList" @click="toLiveDetail(item)" :key="index" class="liveList_main_right_swiper_list_item swiper-slide">
                                <div class="liveList_main_right_swiper_list_item_left">
                                    <img :src="item.teacherHeaderPath" />
                                    <el-tooltip class="item" effect="dark" :content="item.teacherName" placement="bottom">
                                        <p class="liveList_main_right_swiper_list_item_left_title">{{ item.teacherName }}</p>
                                    </el-tooltip>
                                </div>
                                <div class="liveList_main_right_swiper_list_item_right">
                                    <p class="liveList_main_right_swiper_list_item_right_time">{{ item.liveTime }}</p>
                                    <p class="liveList_main_right_swiper_list_item_right_text">{{ item.name }}</p>
                                    <p class="liveList_main_right_swiper_list_item_right_icon" v-if="item.type == 1">
                                        <img src="@/assets/home/live_icon.png" alt />
                                        <span>正在直播</span>
                                    </p>
                                </div>
                                <!--  -->
                            </div>
                        </div>
                    </div>
                    <div class="inl btnl swiper-button-prev" id="aa_right"></div>
                    <div class="inl btnr swiper-button-next" id="aa_left"></div>
                </div>
                <div class="liveList_main_right_timeline">
                    <div
                        class="liveList_main_right_timeline_header"
                        style="width: 100%;
                        margin-left: 20px;
                        font-size: 20px;
                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: bold;
                        color: #333333;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 15px;"
                    >直播课程</div>
                    <div class="liveList_main_right_timeline_box">
                        <div class="swiper-container" id="bb">
                            <div class="swiper-wrapper" ref="swiper">
                                <div class="liveList_main_right_timeline_box_item swiper-slide" v-for="(item,index) in timeLineList" :key="index" @click="changeDate(item, index)">
                                    <p>{{ item }}</p>
                                    <p>
                                        <span></span>
                                        <span :class="changeDateIndex == index ? 'liveList_main_right_timeline_box_item_active' : ''"></span>
                                        <span></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <img class="inl btnl swiper-button-prev" id="bb_right" src="@/assets/course/arrow_left.png" alt />
                        <img class="inl btnr swiper-button-next" id="bb_left" src="@/assets/course/arrow_right.png" alt />
                        <!-- <div ></div> -->
                        <!-- <div ></div> -->

                        <!-- <img src="" alt=""> -->
                        <div class="liveList_main_right_timeline_box_position">
                            <div class="liveList_main_right_timeline_box_position_style" @click="getAll()">
                                <p>全部</p>
                                <p>
                                    <span></span>
                                    <span :class="changeDateIndex == null ? 'liveList_main_right_timeline_box_position_style_blue' : ''"></span>
                                    <span></span>
                                </p>
                            </div>
                        </div>
                        <!-- <img src="" alt=""> -->
                    </div>
                </div>
                <div class="liveList_main_right_liveList">
                    <div class="liveList_main_right_liveList_item" v-for="(item,index) in liveListPage" :key="index">
                        <p class="liveList_main_right_liveList_item_user">
                            <img :src="item.teacherHeaderPath" alt />
                            <el-tooltip class="item" effect="dark" :content="item.teacherName" placement="bottom">
                                <span class="liveList_main_right_liveList_item_user_name">{{ item.teacherName }}</span>
                            </el-tooltip>
                        </p>
                        <p class="liveList_main_right_liveList_item_title">{{ item.name }}</p>
                        <p class="liveList_main_right_liveList_item_explain">
                            <span>{{ item.liveTime }}</span>
                            <img src="@/assets/course/living_icon.png" alt v-if="item.type == 1" />
                            <!-- v-if="item.type==1" -->
                        </p>
                        <p class="liveList_main_right_liveList_item_operate">
                            <span v-if="item.type == 3" style="color:#DB2F2F;">¥{{ item.price }}</span>
                            <el-button type="primary" v-if="item.type == 1" @click="toLiveDetail(item)">进入直播</el-button>
                            <el-button type="primary" plain v-if="item.type == 2" @click="subscribe(item, index)">预约直播</el-button>
                            <el-button type="primary" plain v-if="item.type == 3" @click="goBayLive(item, 2)">购买直播</el-button>
                            <el-button type="info" disabled v-if="item.type == 4">等待开播</el-button>
                        </p>
                        <span class="absolute">{{ item.typeName }}</span>
                    </div>
                </div>
                <div class="block" v-show="liveListPage.length > 0">
                    <el-pagination layout="prev, pager, next" @current-change="handleCurrentChange" :total="liveCount" :hide-on-single-page="hidePagination"></el-pagination>
                </div>
                <div class="coursediv_empty" v-show="!liveListPage.length">
                    <div class="coursediv_empty_img">
                        <img src="@/assets/class_empty.png" alt />
                    </div>
                    <div class="coursediv_empty_text" style="color:#8c8c8c;">无相关直播</div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { pclive_list, first_types, todayFree_liveList, live_reserve, time_line, courseTypeList } from "@/api/course";
import { coursewareList, live_isBuy } from "@/api/livebroadcast";
import Swiper from "swiper"
export default {
    name: 'liveList',
    components: {
        t_header,
        t_footer,
    },
    data() {
        return {
            classifyList: [],
            isType: '1',
            isSort: false,
            todos: false,
            tabIndex: '0',
            tabName: '',
            freeLiveList: [],
            liveListPage: [],
            page: 0,
            isUserInfo: false,
            num: 4,
            hidePagination: false,
            liveCount: 0,
            freeListCount: 0,
            newDate: '2021-11-16',
            timeLineList: [],
            changeDateIndex: null,
            changeDatetime: '',
            liveCount: 0,
            freeLiveCount: 0,
        }
    },
    mounted() {
        setTimeout(() => {
            this._swiper();
        }, 1000);
        this.page = 0
        this.courseTypeList()
        this.time_line()
        this.getCurrentTime()
    },
    methods: {
        // 改变页数
        handleCurrentChange(val) {
            this.page = val - 1;
            this.getlive_list();
        },
        // 点击时间轴的全部
        getAll() {
            this.changeDateIndex = null
            this.changeDatetime = ''
            this.getlive_list()
            this.courseTypeList()
        },
        // 点击切换时间
        changeDate(item, index) {
            this.changeDateIndex = index
            this.changeDatetime = item
            this.getlive_list()
            this.courseTypeList();
        },
        // 时间轴
        time_line() {
            time_line().then(res => {
                if (res.data.code == 0) {
                    var list = res.data.data;
                    var resultItems = [];
                    if (list != null && list.length > 0) {
                        for (var i = 0; i < list.length; i++) {
                            resultItems.push(list[i].slice(5, 10));
                        }
                    }
                    this.timeLineList = resultItems;//res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 父传子
        getTodos(e) {
            this.isUserInfo = e
        },
        // 预约
        subscribe(item, index) {
            const userInfo = this.checkLogin();
            if (!userInfo) {
                this.$message.warning("请登录系统");
                return false
            }
            var obj = {
                liveId: item.id
            }
            live_reserve(obj).then(res => {
                if (res.data.code == 0) {
                    this.getlive_list()
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 去购买
        goBayLive(item, num) {
            const userInfo = this.checkLogin();
            if (!userInfo) {
                this.$message.warning("请登录系统");
                return false
            }
            let routeUrl = this.$router.resolve({
                path: '/orderInfo',
                query: {
                    liveId: item.id,
                    type: num
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        // 课程一级类型
        courseTypeList() {
            let obj = {}
            if (this.changeDatetime) {
                obj.liveDate = this.changeDatetime;
            }
            courseTypeList(obj).then(res => {
                if (res.data.code == 0) {
                    let resultArr = res.data.data;
                    this.classifyList = resultArr;//res.data.data
                    // this.tabIndex = res.data.data[0].id
                    // this.tabName = res.data.data[0].name
                    this.getlive_list()
                    this.freelive_list()
                }
            }).catch(error => {
                console.log(error)
            })
        },
        checkLogin() {
            const userInfo = localStorage.getItem("setuserInfo");
            if (userInfo == null || userInfo == 'null') {
                this.isUserInfo = true;
                setTimeout(() => {
                    this.isUserInfo = false;
                }, 0);
                return false
            } else {
                return userInfo
            }
        },
        // 去直播间
        toLiveDetail(item) {
            // var url='https://live.polyv.cn/watch/'+item.channelId
            // window.open(url, '_blank');
            const userInfo = this.checkLogin();
            if (!userInfo) {
                this.$message.warning("请登录系统");
                return false
            }
            live_isBuy({ channelId: item.channelId }).then((res) => {
                if (res.data.code == 0) {
                let isBuy = res.data.data.isBuy;
                if (isBuy == 1) {
                    let routeUrl = this.$router.resolve({ //liveVideo
                        path: '/videoPlayRoom',
                        query: {
                            channelId: item.channelId,
                            title: item.name,
                            id: item.id,
                            liveType: item.liveType,
                            courseId: item.courseId,
                            coursePackageId: item.coursePackageId,
                            liveType:item.liveType,
                            state:"1",
                        }
                    });
                    window.open(routeUrl.href, '_blank');

                    
                } else {
                    this.$message.error("您还未购买当前套餐");
                }
                } else {
                   this.$message.error(res.data.msg);
                }
            });
            

        },
        // 直播列表
        getlive_list() {
            var obj = {
                firstTypeId: this.tabIndex,
                liveDate: this.changeDatetime,
                page: this.page
            }
            pclive_list(obj).then(res => {
                if (res.data.code == 0) {
                    this.liveListPage = res.data.data;
                    console.log("===",res);
                    this.liveCount = res.data.count
                } else {
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 免费直播列表
        freelive_list() {
            var obj = {
                firstTypeId: this.tabIndex,
            }
            todayFree_liveList(obj).then(res => {
                if (res.data.code == 0) {
                    for (var i in res.data.data) {
                        // if(res.data.data[i].type==1){
                        this.freeLiveList = res.data.data
                        // }
                    }
                    this.freeListCount = res.data.count
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(error => {
                console.log(error)
            })
        },
        tabChange(item) {
            this.tabIndex = item.id
            this.tabName = item.name
            this.getlive_list()
        },
        getCurrentTime() {
            //获取当前时间并打印
            var that = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            // let hh = new Date().getHours();
            // let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
            // let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
            that.newDate = yy + '-' + mm + '-' + dd;
            // that.changeDatetime=yy+'-'+mm+'-'+dd
            // +' '+hh+':'+mf+':'+ss
        },
        // 配置
        _swiper() {
            var effect = 0;

            var swiper1 = new Swiper('#a1', {
                loop: false,
                speed: 1000,
                slidesPerView: 3,
                centeredSlides: false,
                autoplay: false,
                observer: true,
                observeParents: true,
                spaceBetween: 15,
                watchSlidesProgress: true,
                preventLinksPropagation: false,
                slidesPerGroup: 1,   //定义1张图片为一组
                grabCursor: true,//鼠标样式根据浏览器不同而定
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '#aa_left',
                    prevEl: '#aa_right',
                },
                //滑到最后一个隐藏前进按钮
                on: {
                    slideChange: e => {
                        console.log('slideChange', this.num)
                        // if(swiper1.activeIndex+4==this.num){
                        // alert('到底了'+swiper1.activeIndex+4);
                        // }
                    },
                    reachEnd: e => {
                        // console.log('isEnd', swiper1.isEnd)
                        // if(swiper1.isEnd){
                        //     if(Number(this.freeLiveList.length)<Number(this.freeListCount)){
                        //         this.page=this.page+1
                        //     }
                        // }
                    },
                    slideChangeTransitionEnd: () => {
                        if (this.isEnd) {
                            // console.log(this.page)
                            // this.page=Number(this.page)+1
                            // if(Number(this.page)<=4){
                            // console.log('121221')
                            // swiper1.appendSlide('<div class="swiper-slide">这是一个新的slide</div>'); //加到Swiper的最后
                            // }
                            // this.navigation.$nextEl.css('display','none');
                        } else {
                            // this.navigation.$nextEl.css('display','block');  
                        }
                    },
                    slideChangeTransitionStart: function () {
                        // if(this.isBeginning){
                        //     this.navigation.$prevEl.css('display','none');
                        // }else{
                        //     // this.navigation.$prevEl.css('display','block');  
                        // }
                    },

                },

            });
            var swiper2 = new Swiper('#bb', {
                loop: false,
                speed: 1000,
                slidesPerView: 6,
                centeredSlides: false,
                autoplay: false,
                observer: true,
                observeParents: true,
                spaceBetween: 15,
                watchSlidesProgress: true,
                preventLinksPropagation: false,
                slidesPerGroup: 1,   //定义1张图片为一组
                // grabCursor: true,//鼠标样式根据浏览器不同而定
                // disabledClass: 'my-button-disabled',
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },
                navigation: {
                    nextEl: '#bb_left',
                    prevEl: '#bb_right',
                },
                //滑到最后一个隐藏前进按钮
                on: {
                    slideChange: e => {
                        // console.log(swiper2.activeIndex + 3)
                        if (this.changeDateIndex == null) {
                            this.changeDateIndex = 0
                            this.changeDatetime = this.timeLineList[0]
                            swiper2.slideTo(0)
                            this.getlive_list()
                        } else {
                            this.changeDateIndex = swiper2.activeIndex
                            this.changeDatetime = this.timeLineList[swiper2.activeIndex]
                            this.getlive_list()
                        }
                        // if(swiper1.activeIndex+4==this.num){
                        // alert('到底了'+swiper1.activeIndex+4);

                        // }
                    },
                    slideChangeTransitionEnd: () => {
                        if (this.isEnd) {
                            // console.log(this.page)
                            // this.page=Number(this.page)+1
                            // if(Number(this.page)<=4){
                            // console.log('121221')
                            // swiper1.appendSlide('<div class="swiper-slide">这是一个新的slide</div>'); //加到Swiper的最后
                            // }
                            // this.navigation.$nextEl.css('display','none');
                        } else {
                            // this.navigation.$nextEl.css('display','block');  
                        }
                    },
                    slideChangeTransitionStart: function () {
                        // if(this.isBeginning){
                        //     this.navigation.$prevEl.css('display','none');
                        // }else{
                        //     // this.navigation.$prevEl.css('display','block');  
                        // }
                    },

                },

            });
        },
    }
}
</script>

<style>
.my-button-disabled {
    opacity: 1;
}
</style>